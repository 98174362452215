import { buildFileUrl } from "@sanity/asset-utils";
import imageUrlBuilder from "@sanity/image-url";

export const projectId = "g3so7nt7";
export const dataset = "production";

const builder = imageUrlBuilder({
  projectId,
  dataset,
});

export const urlFor = (source: any) => {
  return builder.image(source);
};
export const fileUrlFor = (source: any) => {
  return buildFileUrl(source, {
    projectId,
    dataset,
  });
};

export const subscribeGhost = (email: string) => {
  fetch("https://ghost.hyperbrew.co/members/api/send-magic-link/", {
    headers: {
      accept: "*/*",
      "accept-language": "en-US,en;q=0.7",
      "app-pragma": "no-cache",
      "content-type": "application/json",
      "sec-ch-ua": '"Not_A Brand";v="8", "Chromium";v="120", "Brave";v="120"',
      "sec-ch-ua-mobile": "?0",
      "sec-ch-ua-platform": '"Windows"',
      "sec-fetch-dest": "empty",
      "sec-fetch-mode": "cors",
      "sec-fetch-site": "cross-site",
      "sec-gpc": "1",
      "x-ghost-version": "5.47",
    },
    referrer: "https://hyperbrew.ghost.io/",
    referrerPolicy: "strict-origin-when-cross-origin",
    body: JSON.stringify({
      email,
      emailType: "signup",
      labels: ["website"],
      urlHistory: [
        {
          time: new Date().valueOf(),
          referrerSource: location.hostname,
          referrerMedium: "Embed",
          referrerUrl: location.href,
        },
      ],
    }),
    method: "POST",
    mode: "cors",
    credentials: "omit",
  });
};

// send email to mailchimp
export const subscribeMailchimp = (email: string) => {
  fetch(
    `https://hyperbrew.us11.list-manage.com/subscribe/post-json?u=d9c2f53db78219cf4fa38decd&id=c08b8f80a9&f_id=00ce8de0f0&c=jQuery1900746622945137579_1660954296319&EMAIL=${encodeURIComponent(
      email
    )}&b_d9c2f53db78219cf4fa38decd_c08b8f80a9=&subscribe=Subscribe&_=1660954296320`,
    {
      headers: {
        accept: "*/*",
        "accept-language": "en-US,en",
        "sec-fetch-dest": "script",
        "sec-fetch-mode": "no-cors",
        "sec-fetch-site": "cross-site",
        "sec-gpc": "1",
      },
      referrer: location.href,
      referrerPolicy: "strict-origin-when-cross-origin",
      body: null,
      method: "GET",
      mode: "cors",
      credentials: "omit",
    }
  );
};
// send email to convertkit
export const getItNow = (email: string, formId: string) => {
  fetch(`https://app.convertkit.com/forms/${formId}/subscriptions`, {
    headers: {
      accept: "application/json",
      "accept-language": "en-US,en;q=0.9",
      "content-type":
        "multipart/form-data; boundary=----WebKitFormBoundary5zFTvpykOxUE6AoU",
      "sec-fetch-dest": "empty",
      "sec-fetch-mode": "cors",
      "sec-fetch-site": "cross-site",
      "sec-gpc": "1",
    },
    referrer: location.origin,
    referrerPolicy: "strict-origin-when-cross-origin",
    body: `------WebKitFormBoundary5zFTvpykOxUE6AoU\r\nContent-Disposition: form-data; name="email_address"\r\n\r\n${email}\r\n------WebKitFormBoundary5zFTvpykOxUE6AoU\r\nContent-Disposition: form-data; name="token"\r\n\r\n\r\n------WebKitFormBoundary5zFTvpykOxUE6AoU\r\nContent-Disposition: form-data; name="referrer"\r\n\r\n${location.href}\r\n------WebKitFormBoundary5zFTvpykOxUE6AoU\r\nContent-Disposition: form-data; name="user"\r\n\r\nf79380d9-77a8-4878-b15c-0ec0baa13e58\r\n------WebKitFormBoundary5zFTvpykOxUE6AoU--\r\n`,
    method: "POST",
    mode: "cors",
    credentials: "omit",
  });
};

export const dateStamp = (date: string) => {
  const d = new Date(date);
  return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
};

export const prettyDateStamp = (d: string) => {
  const date = new Date(d);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let day = date.getDate();
  let monthIndex = date.getMonth();
  let year = date.getFullYear();

  return `${months[monthIndex]} ${day}, ${year}`;
};

export const validateEmail = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};
